/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import Button from 'components/elements/Button'
import { Checkbox, FormSelect, Input, SelectMultiple, SelectOption } from 'components/inputs/Input'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import { useHistory } from 'react-router-dom'
import routes from 'lib/constants/routes'
import CountriesService from 'services/CountriesService'
import { SchoolHoliday, SchoolHolidayRequest } from 'interfaces/SchoolHoliday'
import SchoolHolidaysService from 'services/SchoolHolidaysService'
import RegionService from 'services/RegionService'
import { Country } from 'interfaces/Country'
import { countryToLanguageCode } from 'lib/country'

const loadingAreas = {
    delete: 'deleteSchool',
    save: 'saveSchool',
}

export interface SchoolHolidayFormData {
    name: string
    startDate: string
    endDate: string
    isActive: boolean
    regionIds: SelectOption<number>[]
    countryId: SelectOption<Country>
}

interface Props {
    schoolHoliday: SchoolHoliday | null
    schoolHolidayUuid?: string
    isNew: boolean
    updateSchoolHoliday: (schoolHoliday: SchoolHoliday) => void
}

const SchoolHolidayCreateForm = ({ schoolHoliday, schoolHolidayUuid, isNew, updateSchoolHoliday }: Props) => {
    const [countryOptions, setCountryOptions] = useState<{ label: string; identifier: string; data: Country }[]>([])
    const [regionOptions, setRegionOptions] = useState<{ label: string; identifier: string; data: number }[]>([])
    const [selectedCountry, setSelectedCountry] = useState<Country | null>(null)
    const [initialData, setInitialData] = useState<Partial<SchoolHolidayFormData> | null>(null)
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()

    useEffect(() => {
        CountriesService.list()
            .then((countries) => {
                if (!countries) return
                setCountryOptions(countries.map((c) => ({ label: c.name, identifier: c.id.toString(), data: c })))
                const country = countries.find((c) => c.id === schoolHoliday?.countryId)
                if (!country) {
                    setSelectedCountry(countries[0])
                } else {
                    setSelectedCountry(country)
                    setInitialData((prevData) => ({
                        ...prevData,
                        countryId: {
                            label: country.name,
                            identifier: country.id.toString(),
                            data: country,
                        },
                    }))
                }
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('Unable to list countries.')
                    return
                } else {
                    throw error
                }
            })
    }, [schoolHoliday?.countryId])

    useEffect(() => {
        if (!schoolHoliday) return

        setInitialData({
            name: schoolHoliday.name,
            startDate: new Date(schoolHoliday.startDate).toISOString().split('T')[0],
            endDate: new Date(schoolHoliday.endDate).toISOString().split('T')[0],
            isActive: schoolHoliday.isActive,
            regionIds: schoolHoliday.regions.map((r) => ({ label: r.name, identifier: r.id.toString(), data: r.id })),
        })
    }, [schoolHoliday])

    useEffect(() => {
        if (!selectedCountry) return

        const languageCode = countryToLanguageCode(selectedCountry.code)
        if (!languageCode) return
        RegionService.listRegionsByCountry(selectedCountry.code, languageCode)
            .then((regions) => {
                setRegionOptions(regions.map((r) => ({ label: r.regionName, identifier: r.id.toString(), data: r.id })))
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('Unable to list regions.')
                    return
                } else {
                    throw error
                }
            })
    }, [selectedCountry])

    const validateschoolFormData = ({ ...data }: SchoolHolidayFormData) => {
        if (!data.name) {
            formRef.current?.setFieldError('name', 'This field is required')
            return false
        }

        if (isNaN(new Date(data.startDate).getTime())) {
            formRef.current!.setFieldError('startDate', 'Please specify the correct date')
            return false
        }

        if (isNaN(new Date(data.endDate).getTime())) {
            formRef.current!.setFieldError('endDate', 'Please specify the correct date')
            return
        }
        if (new Date(data.endDate).getTime() < new Date(data.startDate).getTime()) {
            formRef.current!.setFieldError('endDate', 'The end date has to be after the start date.')
            return
        }

        return true
    }

    const handleSubmit: SubmitHandler<SchoolHolidayFormData> = (data) => {
        formRef.current!.setErrors({})

        if (!validateschoolFormData(data)) return

        const requestBody: SchoolHolidayRequest = {
            ...data,
            countryId: data.countryId.data.id,
            regionIds: data.regionIds.map((r) => r.data),
        }

        const handleFunction = isNew
            ? SchoolHolidaysService.create(requestBody)
            : SchoolHolidaysService.update(schoolHolidayUuid!, requestBody)

        trackPromise(
            handleFunction
                .then((schoolHoliday) => {
                    toast.success(`School holiday has been ${isNew ? 'created' : 'updated'}`)
                    isNew ? history.push(routes.schoolHolidayRoute(schoolHoliday.uuid)) : updateSchoolHoliday(schoolHoliday)
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while adding new school.')
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <Box>
            <Stack>
                <h3>Update school holiday</h3>
                <Form ref={formRef} initialData={initialData as any} onSubmit={handleSubmit}>
                    <Input name="name" placeholder="Name" />
                    <Input name="startDate" placeholder="Start date" type="date" />
                    <Input name="endDate" placeholder="End date" type="date" />
                    <Checkbox name="isActive" label="Active" placeholder={'Is active'} />
                    {countryOptions ? (
                        <FormSelect
                            name="countryId"
                            options={countryOptions}
                            placeholder="Country"
                            onUpdate={(value: Country) => setSelectedCountry(value)}
                        />
                    ) : null}
                    {regionOptions ? (
                        <SelectMultiple name="regionIds" options={regionOptions} placeholder="Region" label={''} />
                    ) : null}
                    <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                        {'Save'}
                    </Button>
                </Form>
            </Stack>
        </Box>
    )
}

export default SchoolHolidayCreateForm
